import { FC, useEffect, useState } from "react";
import { InputMemo, Modal, TitleBold } from "../../../TrueUI";
import MultiUpload from "../../../TrueUI/Upload/MultiUpload";
import { UploadClaimFileDto } from "../../../../dtos/upload-claim-file-dto";
import { useApiPost } from "../../../../hooks";
import { isAPITotallyCompleteNoContentResponse } from "../../../../utilities/apiFunctions";
import { conditionHasValue } from "../../../../utilities/conditionalSupportFunctions";

type ModalUploadFileProps = {
  isOpen?: boolean;
  setIsOpen?: (isOpen: boolean) => void;
  claimId?: string;
  adjusterMail?: string;
  claimNumber?: string;
};

type ModalUploadInternalProps = {
  files?: { [key: string]: number };
  clear?: boolean;
  formInfo?: UploadClaimFileDto | null;
};
const ModalUploadFile: FC<ModalUploadFileProps> = ({
  isOpen,
  setIsOpen,
  claimId,
  adjusterMail,
  claimNumber,
}) => {
  const [props, setProps] = useState<ModalUploadInternalProps>();

  const {
    responsePost: responseSaveData,
    dispatchPost: dispatchSaveData,
    validatorErrorResponse,
  } = useApiPost(`api/Claims/SaveUploadedFiles`, props?.formInfo);

  const onCloseModal = () => {
    setProps({
      files: {},
      clear: false,
      formInfo: {
        comments: "",
        files: [],
      },
    });
    setIsOpen?.(false);
  };

  const onSaveEvent = () => {
    dispatchSaveData();
  };

  const setClear = (clear: boolean) => {
    setProps({ ...props, clear: clear });
  };

  const onUploadFiles = (files: { [key: string]: number }) => {
    const keys = Object.keys(files ?? []);
    const newFileIds = keys.map((key) => files?.[key]);
    setProps({
      ...props,
      files: files,
      formInfo: {
        ...props?.formInfo,
        files: newFileIds,
      },
    });
  };

  useEffect(() => {
    if (isAPITotallyCompleteNoContentResponse(responseSaveData)) {
      if (validatorErrorResponse == null) {
        onCloseModal();
      }
    }
  }, [responseSaveData]);

  useEffect(() => {
    if (
      conditionHasValue(claimId) &&
      conditionHasValue(adjusterMail) &&
      conditionHasValue(claimNumber)
    ) {
      setProps({
        ...props,
        formInfo: {
          ...props?.formInfo,
          claimId: parseInt(claimId ?? "0"),
          adjusterMail,
          claimNumber,
        },
      });
    }
  }, [claimId, adjusterMail, claimNumber]);

  return (
    <Modal
      id={"upload-file"}
      title={"Upload File(s) For A Claim"}
      open={isOpen}
      cancelEvent={onCloseModal}
      saveEvent={(props?.formInfo?.files?.length ?? 0) > 0 ? onSaveEvent : null}
      size="md"
      showCloseButton
      showCancelTextButton
      saveOverrideLabel="Upload"
      cancelButtonWithConfirmation
    >
      <div>
        <TitleBold display="block" textAlign="start">
          Include any additional comments or details you would like included for
          the examiner (optional)
        </TitleBold>
        <br />
        <InputMemo
          rows={4}
          id={"comments"}
          name={"comments"}
          value={props?.formInfo?.comments}
          onChangeRawValue={(value) => {
            setProps({
              ...props,
              formInfo: {
                ...props?.formInfo,
                comments: value,
              },
            });
          }}
        />
        <br />
        <TitleBold display="block" textAlign="start">
          Drag the files here or click the select button to search for them
        </TitleBold>
        <br />
        <MultiUpload
          autoUpload
          name="upload-files"
          files={props?.files ?? {}}
          saveUrl={"api/PortalFileArchive/UploadMultiple"}
          removeUrl={"api/PortalFileArchive/Remove"}
          clearFiles={props?.clear ?? false}
          resetClearFiles={setClear}
          onUploadFileList={onUploadFiles}
        />
      </div>
    </Modal>
  );
};
export default ModalUploadFile;
