import { FC, useState } from "react";
import Paper from "../../../TrueUI/Containers/Paper";
import { Button, Heading6 } from "../../../TrueUI";
import UploadedFilesTable from "./UploadedFilesTable";
import ModalUploadFile from "./ModalUploadFile";

type UploadedFilesProps = {
  showPanel?: boolean;
  claimId?: string;
  adjusterMail?: string;
  claimNumber?: string;
};

const UploadedFiles: FC<UploadedFilesProps> = ({
  showPanel = true,
  claimId,
  adjusterMail,
  claimNumber,
}) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const PanelTitle = (
    <div
      style={{
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        alignContent: "center",
      }}
    >
      <Heading6>Uploaded Files</Heading6>
      <Button
        variantStyle="outlined"
        sx={{ textTransform: "initial" }}
        onClick={(e) => {
          setIsModalOpen?.(true);
          e.stopPropagation();
        }}
      >
        Upload file(s)
      </Button>
    </div>
  );

  return showPanel ? (
    <Paper
      title={PanelTitle}
      margin="15px 0"
      collapse
      collapseExpanded={false}
      childrenContainerHeight="150px"
    >
      <div id={"uploaded-files"}>
        <UploadedFilesTable
          claimId={claimId}
          refreshTable={isModalOpen === false}
        />
        <ModalUploadFile
          isOpen={isModalOpen}
          setIsOpen={setIsModalOpen}
          claimId={claimId}
          adjusterMail={adjusterMail}
          claimNumber={claimNumber}
        />
      </div>
    </Paper>
  ) : null;
};

export default UploadedFiles;
