import { FC, useEffect } from "react";
import { BaseGridProperties } from "../../../BaseGrid/BaseGridProperties";
import { useBaseGrid } from "../../../BaseGrid/Hooks/useBaseGrid";
import BaseGrid from "../../../BaseGrid/BaseGrid";

type UploadedFilesTableProps = {
  claimId?: string;
  refreshTable?: boolean;
};

const UploadedFilesTable: FC<UploadedFilesTableProps> = ({
  claimId,
  refreshTable,
}) => {
  const gridName = "uploaded-files-table";

  const tableConfigurationGRID: BaseGridProperties = {
    name: gridName,
    getURL: `api/Claims/GetUserClaimFiles?claimId=${claimId}`,
    useManuallyReloadParameters: true,
    columnOptions: [
      { fieldName: "File", width: 50 },
      { fieldName: "Category", width: 25 },
      { fieldName: "AddedBy", width: 25 },
    ],
    advancedOptions: {
      disableSortOrder: true,
    },
    toolbarOptions: {
      showExportButton: false,
      showExcelButton: false,
      showImportButton: false,
      showPDFButton: false,
      showAddButton: false,
      showEditButton: false,
      showSortFilter: false,
      showSaveButton: false,
    },
  };

  const { manuallyReloadParameters } = useBaseGrid(tableConfigurationGRID);

  useEffect(() => {
    if (refreshTable === true) {
      manuallyReloadParameters();
    }
  }, [refreshTable]);

  return <BaseGrid name={gridName} />;
};

export default UploadedFilesTable;
